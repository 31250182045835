export default {
    path:'/dashboard',
    preload:false,
    middlewares: [
      {
        "rms_ui/validatePermissionsMiddleware" : [ 
        ["rms.authenticated"],
          "/login"
        ]
      }
    ]
  }