let blocks = [];
const blocks_req = require.context('./', true, /route\.js$/i);
blocks_req.keys().map(key => {
  let name = key.match(/\w+/)[0];
  let route = blocks_req(key);
  let obj = {
    exclude_paths: route.default.exclude_paths,
    paths: route.default.paths,
    roles: route.default.roles,
    region: route.default.region,
    weight: route.default.weight
  };
  if(route.default.preload){
    obj.component=()=>import(`./${name}/index.vue`);
  }else{
    obj.component=()=>import(
      /* webpackChunkName: "block_[request]" */ 
      /* webpackPrefetch: false */
      `./${name}/index.vue`); 
  }
  blocks.push(obj);
});
export default blocks;