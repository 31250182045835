//load pages routes
let routes = [];
const pages_req = require.context('./', true, /route\.js$/i);
pages_req.keys().map(key => {
  let name = key.match(/\w+/)[0];
  let route = pages_req(key);
  let obj = {
    path: route.default.path,
    name: name,
  };
  if(route.default.middlewares){
    obj.meta={middlewares: route.default.middlewares}
  }
  if(route.default.preload){
    obj.component=()=>import(`./${name}/index.vue`);
  }else{
    obj.component=()=>import(
      /* webpackChunkName: "page_[request]" */ 
      /* webpackPrefetch: false */
      `./${name}/index.vue`); 
  }
  routes.push(obj);
});
export default routes;