let store_modules = {};
const store_modules_req = require.context('./', true, /index\.js$/i);
store_modules_req.keys().map(key => {
    let name = key.match(/\w+/)[0];
    let modul = store_modules_req(key);
    store_modules[name] = {namespaced:true};
    for(let prop in modul.default){
        store_modules[name][prop] = modul.default[prop];
    }
});

export default store_modules;
