export default {
	exclude_paths:false,
  paths:[
    '/',
    '/*',
    '/*/*',
    '/*/*/*',
    '/*/*/*/*'
  ],
  region:'header',//header|topA|topB|left|mainTop|mainBottom|right|bottomB|bottomA|footer
  preload:false,
  weight:1
}