export default {
	name:"erpeach_ui",
	title: "ERPeach",
	layout:{
		leftDrawer:{
			closeOnClick:false
		},
		rightDrawer:{
			closeOnClick:false
		},
		header:{
			classes:[],
			fluid:true,
		},
		topA:{
			classes:[],
			fluid:true,
		},
		topB:{
			classes:[],
			fluid:true,
		},
		body:{
			fluid:true,
			classes:[],
			layout:{
				left:{
					classes:[],
					size:2
				},
				center:{
					layout:{
						mainTop:{
							classes:[],
						},
						content:{
							classes:[],
						},
						mainBottom:{
							classes:[],
						},
						classes:['col-10'],
					}
				},
				right:{
					classes:[],
					size:2
				}				
			}
		},
		bottomB:{
			classes:[],
			fluid:true,//default
		},
		bottomA:{
			classes:[],
			fluid:true,//default
		},
		footer:{
			fluid:true,
			classes:[]
		},
		left_drawer:{
			classes:[]
		},
		right_drawer:{
			classes:[]
		}
	},
	socketServerUrl: `https://${process.env.NODE_ENV==='staging'?'staging.':process.env.NODE_ENV==='dev'||process.env.NODE_ENV==='testing'||process.env.NODE_ENV==='test'?'test.':''}microservices.kizansolutions.com`,
	mounted(){}
}
